
// import VueRouter from 'vue-router'
import {createRouter,createWebHashHistory} from "vue-router";
// import Left from '@/components/nav/left'
// import Top from '@/components/nav/top'
import Layout from '@/views/layout'
import Body from '@/views/body'
// import Main from '@/views/main'


const _routes = [
    {
        path:'/',
        redirect:'/b/user-list'
    },
    {
        path:'/1',
        name:'1',
        component:Layout,
        children:[
            {
              name:'body',
              path:'body',
              component:Body,
                children:[
                    {
                        name:'main1',
                        path:'main1',
                        components: {
                            default:()=>import('@/views/main1')
                        }
                    },
                    {
                        name:'main2',
                        path:'main2',
                        components: {
                            default:()=>import('@/views/main2'),
                        }
                    },
                ]
            },
        ],
    },
    {
      path:'/2',
      name:'2',
      component:Layout,
      children:[
          {
              name:'body2',
              path:'body2',
              components: {
                  default:()=>import('@/views/body2'),
                  body:Body
              }
          }
      ]
    },
    {
        path:'/',
        component:Layout,
        children: [
            {
              path:'/b',
              component: Body,
                redirect:'/b/user-list',
              children: [
                  // {
                  //   path:'user-statistic',
                  //   component:()=>import('@/views/b/user/user-statistic')
                  // },
                  {
                      path:'user-list',
                      component:()=>import('@/views/b/user/user-list'),
                  },
                  {
                      path:'user-vip-list',
                      component:()=>import('@/views/b/user/user-vip-list')
                  },
                  {
                      path:'user-detail',
                      component: import('@/views/b/user/user-detail')
                  },
                  {
                      path:'user-recharge-package-list',
                      component:()=>import('@/views/b/user/user-recharge-package-list')
                  },
                  {
                      path:'playground-list',
                      component:()=>import('@/views/b/pk/playground-list')
                  },
                  {
                      path:'coach-list',
                      component:()=>import('@/views/b/pk/coach-list')
                  },
                  {
                      path:'play-schedule-list',
                      component:()=>import('@/views/b/pk/play-schedule-list')
                  },
                  {
                      path:'play-schedule-table',
                      component:()=>import('@/views/b/pk/play-schedule-table')
                  },
                  {
                      path:'sport-list',
                      component:()=>import('@/views/b/pk/sport-list')
                  },
                  {
                      path:'order-recharge-list',
                      component:()=>import('@/views/b/order/order-recharge-list')
                  },
                  {
                      path:'order-play-list',
                      component:()=>import('@/views/b/order/order-play-list')
                  },
                  {
                      path:'coupon-list',
                      component:()=>import('@/views/b/market/coupon-list')
                  },
                  {
                      path:'coupon-record-list',
                      component:()=>import('@/views/b/market/coupon-record-list')
                  },
                  {
                      path:'coach-sheet-list',
                      component:()=>import('@/views/b/market/coach-sheet-list')
                  },
                  {
                      path:'youth-sheet-list',
                      component:()=>import('@/views/b/market/youth-sheet-list')
                  },
                  {
                      path:'setting-group-list',
                      component:()=>import('@/views/b/setting/setting-group-list')
                  },
                  {
                      path:'setting-group-item-list',
                      component:()=>import('@/views/b/setting/setting-group-item-list')
                  },
                  {
                      path:'coupon-detail',
                      component:()=>import('@/views/b/market/coupon-detail')
                  },
                  {
                      path:'play-experience-package-list',
                      component:()=>import('@/views/b/market/play-experience-package-list')
                  },
                  {
                      path:'employee-list',
                      component:()=>import('@/views/b/store/employee-list')
                  },
                  {
                      path:'order-play-setting',
                      component:()=>import('@/views/b/setting/order-play-setting')
                  },
                  {
                      path:'recharge-setting',
                      component:()=>import('@/views/b/setting/recharge-setting')
                  },
                  {
                      path:'match-season-setting',
                      component:()=>import('@/views/b/setting/match-season-setting')
                  },
                  {
                      path:'we-app-setting',
                      component:()=>import('@/views/b/setting/we-app-setting')
                  },
                  {
                      path:'advance-course-list',
                      component:()=>import('@/views/b/pk/advance-course-list')
                  },
                  {
                      path:'advance-course-detail',
                      component:()=>import('@/views/b/pk/advance-course-detail')
                  },
                  {
                      path:'video-list',
                      component:()=>import('@/views/b/video/video-list')
                  },
                  {
                      path:'video-detail',
                      component:()=>import('@/views/b/video/video-detail')
                  },
                  {
                      path:'match-season-list',
                      component:()=>import('@/views/b/pk/match-season-list')
                  },
                  {
                      path:'verify-record-list',
                      component:()=>import('@/views/b/order/verify-record-list')
                  },
                  {
                      path:'balance-record-list',
                      component:()=>import('@/views/b/finance/balance-record-list')
                  },
                  {
                      path:'balance-bill-list',
                      component:()=>import('@/views/b/finance/balance-bill-list')
                  },
                  {
                      path:'balance-bill-detail',
                      component:()=>import('@/views/b/finance/balance-bill-detail')
                  }
              ]
            },

        ],
    },
    {
        path:'/login',
        component:()=>import('@/views/login')
    },
]

export const router = createRouter({
    history:createWebHashHistory(),
    routes:_routes,
})

export default router


