<template>
    <div style="height:100%;background:#f0f2f5;">
        <!-- 顶部菜单栏 -->
        <div style="height:48px;background:#001529;position: fixed;left: 0;right:0;top:0;width: 100%;overflow: hidden;z-index:3000">
            <div style="display:flex;justify-content: space-between;">
<!--                <div style="display:flex;align-items: center;justify-content: center;width:208px;max-width: 208px;min-width: 208px">-->
                    <div style="display:flex;align-items: center;justify-content:flex-start;width:208px;max-width: 208px;min-width: 208px;height:100%;">
<!--                    <img src="@/assets/banbanlogo180_60.png" style="height:48px;"/>-->
                    <!--                    <img src="@/assets/logo_256px.png" style="height:48px;"/>-->
                        <div style="height:48px;display:flex;flex-direction: row;align-items: center;">
                            <img src="@/assets/hado_arean_logo.png" style="height:16.24px;width:208px;"/>
                        </div>
<!--                    <span style="color:#fff;">HADO后台管理</span>-->
                    </div>
                <div style="flex:1;position:relative">
                    <el-menu mode="horizontal" background-color="#001529" text-color="hsla(0,0%,100%,.65)" active-text-color="#fff">
<!--                        <el-menu-item index="1">导航</el-menu-item>-->
<!--                        <el-sub-menu index="2">-->
<!--                            <template #title>fuck_2</template>-->
<!--                            <el-menu-item index="2_1">fuck_a</el-menu-item>-->
<!--                            <el-menu-item index="2_2">fuck_b</el-menu-item>-->
<!--                            <el-menu-item index="2_3">fuck_c</el-menu-item>-->
<!--                        </el-sub-menu>-->
<!--                        <el-menu-item index="3">fuck_3</el-menu-item>-->
<!--                        <el-menu-item index="4">fuck_4</el-menu-item>-->
                    </el-menu>
                    <el-button type="text" style="position: absolute;right:10px;top:10px;color:#ffffff" @click="logoutTap">登出</el-button>
                </div>
            </div>
<!--            <router-link to="main1">fuck</router-link>-->
<!--            <router-link to="main2">fuck_fuck</router-link>-->
        </div>
        <!-- 主页面 -->
        <div style="height: calc(100vh - 48px);padding-top: 48px;">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "layout",
        methods:{
            async logoutTap(){
                await this.$store.dispatch('user/logout')
                this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            }
        }
    }
</script>

<style scoped>
    .el-menu--horizontal>.el-menu-item{
        height:48px;
        line-height: 48px;
    }
    /deep/.el-menu--horizontal>.el-sub-menu .el-sub-menu__title{
        height:48px;
        line-height: 48px;
    }
</style>
